.Categories {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin: 0.5em 0;
}

.CategoryItem {
    font-weight: 500;
    color: var(--dark-font-color);
    padding: 0.3em 0.5em;
    border-radius: 5pt;
    margin: 0.15em;
    background-color: var(--main-actionable-color);
    cursor: default;
    opacity: 0.9;
}

.CategoryItem.active {
    background-color: var(--main-actionable-active-color);
}

.CategoryItem:hover {
    opacity: 1;
}
