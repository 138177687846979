.FullSizeBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Popup {
    opacity: 1;
    border-color: var(--main-active-color);
    background-color: var(--secondary-bg-color);
    width: 80%;
    height: 70%;
    border-style: solid;
    border-radius: 7pt;
    border-width: 2pt;
    overflow: scroll;
}

.NewExpenseGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
}

.NewExpenseGroup input {
    height: 2em;
    min-width: 150pt;
}

.NewExpenseGroup select {
    height: 100%;
    border-radius: 0 2pt 2pt 0;
    border-style: solid;
    border-width: 1px;
    border-color: #666;
    border-left: 0;
}

.NewExpenseGroup input::-webkit-outer-spin-button,
.NewExpenseGroup input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.NewExpenseGroup input[type="number"] {
    -moz-appearance: textfield;
}

.NewExpenseGroup > div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.NewExpenseGroup h4 {
    margin-top: 8pt;
    margin-bottom: 4pt;
    margin-left: 4pt;
    opacity: 0.8;
    font-size: 0.8em;
    text-transform: uppercase;
}

.InputWithCurrency {
    width: 100%;
    display: flex;
    align-items: baseline;
}

.Currency {
    padding-right: 2em;
    width: 100%;
}

.CurrencyEur {
    width: 0;
    left: -1.5em;
    position: relative;
}

.TabGroup {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
}

.TabGroup > div {
    margin: 1pt;
    color: var(--dark-font-color);
    background-color: var(--main-actionable-color);
    padding: 3pt;
}

.TabGroup > div.active {
    background-color: var(--main-actionable-active-color);
}

.Submit {
    margin: 10pt;
    color: var(--dark-font-color);
    background-color: var(--main-actionable-color);
    padding: 3pt;
    max-width: 50pt;
    text-align: center;
}

.Submit:active {
    background-color: var(--main-actionable-active-color);
}
