.LoginRoot {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login > * {
    width: 100%;
}

.Login {
    height: 400pt;
    width: 400pt;
    border-radius: 6pt;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TitleLine {
    display: flex;
    align-items: center;
}

.TitleLine > hr {
    flex-grow: 1;
}

.TitleLine > span {
    padding: 10pt;
    font-size: 1.2em;
    font-weight: 500;
}

.Login input {
    border-color: var(--main-active-color);
    border-style: solid;
    font-size: 1.2em;
    height: fit-content;
}

.Button {
    padding: 10pt;
    opacity: 0.9;
    color: var(--dark-font-color);
    background-color: var(--main-actionable-color);
    margin: 10pt;
    font-weight: 500;
    pointer-events: auto;
    display: flex;
    align-items: center;
}

.Button:active {
    background-color: var(--main-actionable-active-color);
}

.Button:hover {
    opacity: 1;
}
