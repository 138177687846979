.DateControl {
    display: flex;
    justify-content: center;
}

.DateControl .Element:first-child {
    border-radius: 10pt 0 0 10pt;
    border-left: none;
}

.DateControl .Element:last-child {
    border-radius: 0 10pt 10pt 0;
    border-right: none;
}

.DateControl .Element {
    padding: 3pt 12pt;
    background: var(--main-actionable-color);
    color: var(--dark-font-color);
    border-left: solid 1pt var(--secondary-bg-color);
    border-right: solid 1pt var(--secondary-bg-color);
    font-weight: 500;
    opacity: 0.9;
    cursor: default;
}

.DateControl .Element.NotStable:active {
    background: var(--main-actionable-active-color);
}

.DateControl .Element.active {
    background: var(--main-actionable-active-color);
}

.DateControl .Element:hover {
    opacity: 1;
}
