.Expenses {
    display: flex;
    flex-flow: column;
    margin: 0.5em 0;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
}

.ExpensesItem {
    padding: 0.3em;
    margin: 0.2em 0;
    background-color: var(--main-inactive-color);
    color: var(--dark-font-color);
    opacity: 0.9;
}

.ExpensesItem.upcoming {
    background-color: var(--main-active-color);
    font-weight: 400;
}

.ExpensesItem:hover {
    opacity: 1;
}
