body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100svh;
    min-height: 400pt;

    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

#root {
    height: 100%;
}

* {
    color: #666;
    --dark-font-color: black;
    --secondary-bg-color: white;
    --main-inactive-color: #ddbf8f;
    --main-active-color: #9ad0f5;
    --main-actionable-active-color: #ff6384;
    --main-actionable-color: #ffb1c1;
}

.Warning {
    color: var(--main-actionable-active-color);
}
