.Actions {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    width: 100%;
    pointer-events: none;
}

.ActionItem {
    opacity: 0.9;
    color: var(--dark-font-color);
    background-color: var(--main-actionable-color);
    margin: 10pt;
    font-weight: 500;
    pointer-events: auto;
}

.ActionItem:active {
    background-color: var(--main-actionable-active-color);
}

.ActionItem:hover {
    opacity: 1;
}

.Add {
    cursor: default;
    border-radius: 100%;
    width: 1.4em;
    height: 1.4em;
    text-align: center;
    font-size: 2em;
}
