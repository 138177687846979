.Header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5pt;
    padding: 5pt;
    background-color: var(--main-active-color);
}
.Header > div {
    margin: 0px 5px;
    color: var(--light-font-color);
}

.User {
    display: inline;
    position: absolute;
    left: 0;
}
.Title {
    display: inline;
    font-size: 1.1em;
    font-weight: bold;
    color: var(--secondary-bg-color) !important;
    opacity: 0.7;
}
.Settings {
    display: inline;
    position: absolute;
    right: 0;
}
